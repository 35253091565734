// import { Balance } from "./balance";
import { Workspace } from "./workspace";
import { Notification } from "./notification";
import { Profile } from "./profile";

export function Header() {
  return (
    <header className="w-full h-16 bg-white">
      <div className="w-full h-full px-4 flex items-center justify-between">
        <span className="font-medium text-[21px]">Dashboard</span>
        <div className="flex gap-4 items-center">
          {/* <Balance /> */}
          <Workspace />
          <Notification news={0} />
          <Profile photo="https://avatars.githubusercontent.com/u/100789151?v=4" name="Francisco Carlos" account="C/C: 1234567-8" />
        </div>
      </div>
    </header>
  )
}
