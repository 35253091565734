/* eslint-disable react-hooks/exhaustive-deps */
import { cn } from "@/shared/libs/utils";
import { formatCpfCnpj, formatDate, formatStatus } from "@/shared/libs/format";
import { Info } from "./info";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shared/components/ui/select";
import { MedItemModel } from "@/domain/model/medItemModel";
import { useEffect, useState } from "react";

type Props = {
  data: MedItemModel
  info: string
  setInfo: (info: string) => void
}

const finishStatus = [
  'resolved',
  'closed',
]

const typeOptions = [
  'application_fraud',
  'mule_account',
  'scammer_account',
  'other',
]

export function DataRequisition({ data, info, setInfo }: Props) {
  const [createdDate, createdHour] = formatDate(data.createdAt)
  const [deadlineDate, deadlineHour] = formatDate(data.expirationDate)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (data && typeOptions.includes(data.response.fraudType)) {
      setInfo(data.response.fraudType)
      setDisabled(true)
    }
  }, [data])

  return (
    <div className="flex gap-3 py-4 border-b border-[#E5E5E5]">
      <div className="flex flex-col gap-1 flex-1">
        <span className="font-semibold text-xl">Dados da Requisição</span>
        <span className="text-sm">Detalhes sobre o pedido de devolução aberto e prazos de ação</span>
      </div>
      <div className="flex flex-col gap-4 flex-1">
        <Info title="Status:">
          <div className={cn(
            "font-semibold text-sm py-1 px-2 rounded-full border",
            data.infractionReportStatus === "approved automatic" && "text-states-info border-states-info",
            data.infractionReportStatus === "acknowledged" && "text-states-error border-states-error",
            finishStatus.includes(data.infractionReportStatus) && "text-states-sucess border-states-sucess",
          )}>
            {formatStatus(data.infractionReportStatus)}
          </div>
        </Info>
        <Info title="Data de Criação:">
          <span>{createdDate}, {createdHour}</span>
        </Info>
        <Info title="Data de Limite:">
          <div className={cn(
            "font-semibold text-sm py-1 px-2 rounded-full border",
            data.infractionReportStatus === "approved automatic" && "text-states-info border-states-info",
            data.infractionReportStatus === "acknowledged" && "text-states-error border-states-error",
            finishStatus.includes(data.infractionReportStatus) && "text-states-sucess border-states-sucess",
          )}>
            <span>{deadlineDate}, {deadlineHour}</span>
          </div>
        </Info>
        <Info title="Documento do pagador:">
          <span>{formatCpfCnpj(data.payerDocumentNumber)}</span>
        </Info>
        <Info title="Tipo de Infração:">
          <Select value={info} onValueChange={(e) => setInfo(e)} disabled={disabled}>
            <SelectTrigger className="w-44 h-9 bg-[#EFEEF2]">
              <SelectValue placeholder="[tipo]" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="application_fraud">Fraude</SelectItem>
                <SelectItem value="mule_account">Empresa laranja</SelectItem>
                <SelectItem value="scammer_account">Scam</SelectItem>
                <SelectItem value="other">Outros</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </Info>
      </div>
      <div className="flex flex-col gap-4 flex-1">
        <div className="flex gap-2">
          <span className="font-semibold text-sm">Resumo:</span>
          <span>{data.infractionReportEvents.at(-1)?.eventDetails}</span>
        </div>
        <div className="flex gap-2 items-center">
          <span className="font-semibold text-sm">Detalhes:</span>
          <span>{data.infractionReportDetails}</span>
        </div>
      </div>
    </div>
  )
}
