import { GetInfractionTransaction } from "@/domain/useCase/getInfractionTransition"
import { KEY_CACHE } from "@/infra/cache/constants/keys";
import { LocalStorageAdapter } from "@/infra/cache/localStorageAdapter";
import { API_ENDPOINTS, makeApiUrl } from "@/infra/http/constants/url";
import { FetchHttpClient } from "@/infra/http/fetchHttpClient";
import { RemoteGetInfractionTransaction } from "@/shared/data/useCases/getInfractionTransaction";
import { generateHeaderPrivate } from "@/shared/libs/generate";

export const makeRemoteGetInfractionTransaction = (): GetInfractionTransaction => {
  const cache = new LocalStorageAdapter();
  const user = cache.get(KEY_CACHE.USER) as string;
  const account = cache.get(KEY_CACHE.ACCOUNT) as string;

  return new RemoteGetInfractionTransaction(
    makeApiUrl(API_ENDPOINTS.Private.INFRACTION_TRANSACTION),
    new FetchHttpClient(),
    generateHeaderPrivate(user, account)
  )
}
