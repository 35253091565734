import { PayloadModel } from "@/domain/model/payloadModel";
import { API_TOKEN } from "@/infra/http/constants/url";

export const filterPayload: PayloadModel = {
  page: 0,
  pageSize: 20,
  orderBy: 'expirationDate',
  order: 'desc',
};

export const generateTimeWithGMTOffset = () => {
  const date = new Date();

  const offset = date.getTimezoneOffset();
  const absoluteOffset = Math.abs(offset);
  const hours = Math.floor(absoluteOffset / 60);
  const minutes = absoluteOffset % 60;

  const sign = offset <= 0 ? '+' : '-';
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `GMT${sign}${formattedHours}:${formattedMinutes}`;
}

export const  generateHeaderPublic = () => {
  return {
    'content-type': 'application/json',
    'x-access-token': API_TOKEN,
    'x-gmt': generateTimeWithGMTOffset()
  }
}

export const generateHeaderPrivate = (user: string, account: string) => {
  return {
    'content-type': 'application/json',
    'x-access-token': API_TOKEN,
    'x-gmt': generateTimeWithGMTOffset(),
    'x-person-token': user,
    'x-account-key': account,
  }
}
