import { Skeleton } from "@/shared/components/ui/skeleton"

export const DetailLoading = () => {
  return (
    <div className="flex flex-col gap-4 w-full">
      <Skeleton className="w-full h-[208px]" />
      <Skeleton className="w-full h-[104px]" />
      <Skeleton className="w-full h-[124px]" />
    </div>
  )
}
