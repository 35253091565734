/* eslint-disable react-hooks/exhaustive-deps */
import { SubContentWhite } from "../../../../shared/layout/Content";
import { useNavigate, useParams } from "react-router-dom";
import { DataRequisition } from "../../components/Card/DataRequisition";
import { DataInfraction } from "../../components/Card/DataInfraction";
import { DataResponse } from "../../components/Card/DataResponse";
import { GetInfractionTransaction } from "@/domain/useCase/getInfractionTransition";
import { useEffect, useState } from "react";
import { DetailLoading } from "../../components/Skeleton/detail-loading";
import { MedItemModel } from "@/domain/model/medItemModel";
import { verifyAction } from "../../utils/verify";
import { Button } from "@/shared/components/ui/button";
import { useToast } from "@/shared/hooks/use-toast";
import { PutInfractionReport } from "@/domain/useCase/putInfractionReport";

type Props = {
  remoteGetInfractionTransaction: GetInfractionTransaction
  remotePutInfractionReport: PutInfractionReport
}

export default function MedViewDetail({ remoteGetInfractionTransaction, remotePutInfractionReport }: Props) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { id } = useParams();
  const [hasFetched, setHasFetched] = useState(false);
  const [details, setDetails] = useState<MedItemModel | null>(null);
  const [typeInfraction, setTypeInfraction] = useState<string>("");
  const [description, setDescription] = useState<string>("");


  useEffect(() => {
    if (id && !hasFetched) {
      getInfractionTransaction(id)
      setHasFetched(true)
    }
  }, [hasFetched])

  const getInfractionTransaction = async (infraId: string) => {
    try {
      const response = await remoteGetInfractionTransaction.execute(infraId);
      setDetails(response);
    } catch (error) {
      console.error(error);
    }
  }

  const handleClick = (url: string) => {
    navigate(url)
  }

  const handleSubmit = async (status: boolean) => {
    if (typeInfraction === "") {
      toast({
        variant: "destructive",
        title: "Tipo de Infração não informado",
      })
      return;
    }

    if (description === "") {
      toast({
        variant: "destructive",
        title: "Descrição não informada",
      })
      return;
    }

    const payload = {
      analysisResult: status ? "agreed" : "disagreed",
      fraudType: typeInfraction,
      analysisDetails: description
    }

    if (id) {
      try {
        const response = await remotePutInfractionReport.execute(id, payload);

        if (response) {
          toast({
            variant: "default",
            title: "Relatório enviado com sucesso",
          })

          setTimeout(() => {
            navigate(`/med`)
          }, 2000);
        }
      } catch (error) {
        toast({
          variant: "destructive",
          title: "Houve um erro ao tentar enviar o relatório",
        })
        console.error(error);
      }
    }
  }

  return (
    <>
      <div className="w-full border-b border-[#E5E5E5] flex items-center">
        <span className="text-base font-semibold border-b-2 border-[#636363] py-3 px-3 cursor-default">Detalhe da Infração</span>
        <span className="text-base font-semibold py-3 px-3 text-states-info cursor-pointer" onClick={() => handleClick(`/med/historico/${id}`)}>Histórico</span>
      </div>
      <SubContentWhite>
        {details &&
          <div className="flex flex-col gap-4 w-full">
            <DataRequisition data={details} setInfo={setTypeInfraction} info={typeInfraction} />
            <DataInfraction data={details} />
            <DataResponse data={details} setInfo={setDescription} info={description} />
            {verifyAction(details.response) &&
              <div className="flex justify-end gap-4">
                <Button variant="outline" className="h-9 rounded-lg" onClick={() => handleSubmit(true)}>Aprovar</Button>
                <Button className="h-9 rounded-lg bg-secondary-dark text-white" onClick={() => handleSubmit(false)}>Recusar</Button>
              </div>
            }
          </div>
        }
        {!details && <DetailLoading />}
      </SubContentWhite>
    </>
  )
};
