export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_TOKEN = process.env.REACT_APP_API_TOKEN;

export namespace API_ENDPOINTS {
  export enum Public {
    LOGIN = 'login',
  }

  export enum Private {
    WORKSPACE = 'workspace',
    ACCOUNTS_LIST = 'accounts/list',
    INFRACTION_LIST = 'infractionreport/list',
    INFRACTION_TRANSACTION = 'infractionreport/transaction',
    INFRACTION_REPORT = 'infractionreport/close',
  }
}

export const makeApiUrl = (endpoint: string) => `${API_BASE_URL}v2/${endpoint}`;
