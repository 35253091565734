import { PayloadInfractionModel } from "@/domain/model/payloadInfractionModel";
import { PutInfractionReport } from "@/domain/useCase/putInfractionReport";
import { HttpClient, HttpStatusCode } from "@/infra/protocol/http/httpClient";

export class RemotePutInfractionReport implements PutInfractionReport {
  constructor(
    public url: string,
    public httpClient: HttpClient,
    public headers: any
  ) {
    this.url = url;
    this.httpClient = httpClient;
    this.headers = headers;
  }

  async execute(id: string, payload: PayloadInfractionModel): Promise<any> {
    const payloadJSON = JSON.stringify(payload);

    const response = await this.httpClient.request({
      url: this.url + '?infractionReportKey=' + id,
      method: 'put',
      headers: this.headers,
      body: payloadJSON
    });

    switch (response.status) {
      case HttpStatusCode.serverError:
        throw new Error('Erro no servidor');
      default:
        return response.data;
    }
  }

}
