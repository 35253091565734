/* eslint-disable react-hooks/exhaustive-deps */
import { MedItemModel } from "@/domain/model/medItemModel";
import { Textarea } from "@/shared/components/ui/textarea";
import { useEffect, useState } from "react";

type Props = {
  data: MedItemModel
  info: string
  setInfo: (info: string) => void
}

export function DataResponse({ info, setInfo, data }: Props) {
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (data && data.response.analysisDetails) {
      setInfo(data.response.analysisDetails)
      setDisabled(true)
    }
  }, [data])

  return (
    <div className="flex gap-3 py-4 border-b border-[#E5E5E5]">
      <div className="flex flex-col gap-1">
        <span className="font-semibold text-xl">Resposta da Instituição</span>
        <span className="text-sm">Por favor, insira o máximo de detalhes para validar o posicionamento em relação à solicitação</span>
      </div>
      <div className="flex flex-col gap-2 w-full">
        <span className="font-semibold text-sm">Defesa (até 250 caracteres):</span>
        <Textarea
          placeholder="Descrição de resposta validando a recusa aqui..."
          className="h-24 w-full bg-[#EFEEF2] resize-none"
          maxLength={250}
          value={info}
          disabled={disabled}
          onChange={(e) => setInfo(e.target.value)}
        />
      </div>
    </div>
  )
}
