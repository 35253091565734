import { BrowserRouter } from 'react-router-dom'
import './shared/theme/global.css'
import RoutesComponent from './routes';
import MakeAuthContext from './factory/contexts/AuthContext';
import { Toaster } from './shared/components/ui/toaster';

function App() {
  return (
    <BrowserRouter>
      <MakeAuthContext>
        <RoutesComponent />
      </MakeAuthContext>
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
