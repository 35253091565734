/* eslint-disable react-hooks/exhaustive-deps */
import { WorkspaceModel } from "@/domain/model/workspaceModel";
import { Button } from "@/shared/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/shared/components/ui/sheet";
import { useAuth } from "@/shared/context/auth";
import { ChevronDown, LoaderCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { Information } from "./styles";
import { Label } from "@/shared/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/shared/components/ui/select";

export function Workspace() {
  const { workspaces, getAccountId, setAccountId } = useAuth();
  const [account, setAccount] = useState<WorkspaceModel | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<string>('');

  useEffect(() => {
    const id = getAccountId() as string;
    if (id && workspaces) {
      const item = workspaces.find((workspace) => workspace.accountKey === id)
      setSelectedAccount(id);

      if (item) {
        setAccount(item);
      }
    }
  }, [workspaces]);

  function handleWorkspace(value: string) {
    setSelectedAccount(value)
    setAccountId(value);
  }

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button className="h-12 w-64 focus:ring-0 bg-[#f3f3f3]" disabled={!account}>
          {account && <div className="flex items-center justify-between gap-4">
            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-states-sucess rounded-full"></div>
              <div className="flex flex-col">
                <span className="text-[10px] text-text-dark font-semibold max-w-40 whitespace-nowrap overflow-hidden text-ellipsis">{account.bankName} - {account.ownerName}</span>
                <span className="font-semibold text-primary-dark max-w-40 whitespace-nowrap overflow-hidden text-ellipsis">{account.subOwnerName}</span>
              </div>
            </div>
            <ChevronDown className="text-text-dark" />
          </div>}
          {!account && <LoaderCircle className="animate-spin" />}
        </Button>
      </SheetTrigger>
      <SheetContent className="min-w-[640px] p-8">
        {account &&
          <div className="flex flex-col gap-6">
            <div className="flex flex-col">
              <span className="text-xl font-semibold text-[#414141]">Conta ativa</span>
              <span className="text-[#636363]">Acesse uma nova conta para ver os dados referentes à mesma</span>
            </div>
            <div className="flex flex-col gap-4">
              <Information title="Titular" value={account?.ownerName} />
              <Information title="Instituição" value={account?.bankName} />
              <div className="flex items-center gap-6">
                <Information title="Conta" value={account?.accountNumber} />
                <Information title="Agência:" value={account?.accountBranch} />
              </div>
              <Information title="CNPJ" value={'000.000.000/000-00'} />
              <Information title="Abertura da conta" value="01/01/2023" />
            </div>

            {workspaces &&
              <div className="flex flex-col gap-4">
                <Label className="text-primary-dark font-semibold">Alternar conta:</Label>
                <Select onValueChange={(e) => handleWorkspace(e)} value={selectedAccount}>
                  <SelectTrigger className="w-full focus:ring-0 border-[#C6C3D0]">
                    <SelectValue placeholder="Selecione a conta" className="overflow-hidden text-ellipsis whitespace-nowrap" />
                  </SelectTrigger>
                  <SelectContent>
                    {workspaces.map((workspace) => (
                      <SelectItem key={workspace.accountKey} value={workspace.accountKey}>
                        {workspace.displayName}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            }
          </div>
        }
      </SheetContent>
    </Sheet>
  )
}
