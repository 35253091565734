// import { InfoCard } from "../../../shared/components/Card/info";
import { SubContentWhite } from "../../../shared/layout/Content";
// import { ListOrdered, Siren, BadgeAlert, FileCheck2 } from "lucide-react";
import { Filters } from "../components/Filters";
import { Table } from "../components/Table";
import { Pagination } from "../components/Pagination";

export default function MedList() {
  return (
    <SubContentWhite className="gap-4">
      {/* <div className="flex gap-2">
        <InfoCard className="flex-1 gap-1">
          <ListOrdered size={24} className="text-states-error"/>
          <span className="font-semibold text-2xl">15</span>
          <span className="text-sm font-normal text-states-error">Recebidas</span>
        </InfoCard>
        <InfoCard className="flex-1 gap-1">
          <Siren size={24} className="text-states-error"/>
          <span className="font-semibold text-2xl">3</span>
          <span className="text-sm font-normal text-states-error">Urgentes</span>
        </InfoCard>
        <InfoCard className="flex-1 gap-1">
          <BadgeAlert size={24} className="text-states-error"/>
          <span className="font-semibold text-2xl">1</span>
          <span className="text-sm font-normal text-states-error">Em espera</span>
        </InfoCard>
        <InfoCard className="flex-1 gap-1">
          <FileCheck2 size={24} className="text-states-error"/>
          <span className="font-semibold text-2xl">1</span>
          <span className="text-sm font-normal text-states-error">Concluídas</span>
        </InfoCard>
      </div> */}

      <Filters />

      <Table />

      <Pagination />
    </SubContentWhite >
  );
}
