import { useAuth } from '@/shared/context/auth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PublicRoute = () => {
  const { getUserId } = useAuth()
  const location = useLocation();

  if (!!getUserId()) {
    return <Navigate to="/med" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
