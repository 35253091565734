import { formatCpfCnpj, formatInfractionType, formatToReal } from "@/shared/libs/format";
import { Table as List, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/shared/components/ui/table";
import { ActionCell, DateCell, StatusCell } from "./cell";
import { useMedList } from "../../contexts/med-list";
import { TableLoading } from "../Skeleton/table-loading";
import { ScrollArea } from "@/shared/components/ui/scroll-area";

export function Table() {
  const { medList } = useMedList();

  return (
    <>
      {medList &&
        <ScrollArea className="h-[650px]">
          <List>
            <TableHeader className="bg-[#F3F3F3]">
              <TableRow>
                <TableHead className="font-semibold text-sm text-[#414141]">Status</TableHead>
                <TableHead className="font-semibold text-sm text-[#414141]">Criado em</TableHead>
                <TableHead className="font-semibold text-sm text-[#414141]">Data limite</TableHead>
                <TableHead className="font-semibold text-sm text-[#414141]">Valor</TableHead>
                <TableHead className="font-semibold text-sm text-[#414141]">Docum. Pagador</TableHead>
                <TableHead className="font-semibold text-sm text-[#414141]">Docum. Recebedor</TableHead>
                <TableHead className="font-semibold text-sm text-[#414141]">Tipo</TableHead>
                <TableHead className="font-semibold text-sm text-[#414141]">Ações</TableHead>
              </TableRow>
            </TableHeader>


            <TableBody>
              {medList.map((item, index) => (
                <TableRow key={item.id + index}>
                  <TableCell>
                    <StatusCell status={item.alarm} />
                  </TableCell>
                  <TableCell>
                    <DateCell date={item.createdAt} />
                  </TableCell>
                  <TableCell>
                    <DateCell date={item.expirationDate} />
                  </TableCell>
                  <TableCell>{formatToReal(item.amount)}</TableCell>
                  <TableCell>{formatCpfCnpj(item.payerDocumentNumber)}</TableCell>
                  <TableCell>{formatCpfCnpj(item.aliasDocument)}</TableCell>
                  <TableCell>{formatInfractionType(item.infractionReportType)}</TableCell>
                  <TableCell>
                    <ActionCell has_response={!(item.status === 'closed')} finished={item.lastUpdate} id={item.infractionReportKey} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </List>
        </ScrollArea>

      }

      {!medList && <TableLoading />}
    </>
  )
}
