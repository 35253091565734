import { GetInfractionTransaction } from "@/domain/useCase/getInfractionTransition";
import { HttpClient, HttpStatusCode } from "@/infra/protocol/http/httpClient";
export class RemoteGetInfractionTransaction implements GetInfractionTransaction {
  constructor(
    public url: string,
    public httpClient: HttpClient,
    public headers: any
  ) {
    this.url = url;
    this.httpClient = httpClient;
    this.headers = headers;
  }

  async execute(id: string): Promise<any> {
    const response = await this.httpClient.request({
      url: this.url + '?infractionReportKey=' + id,
      method: 'get',
      headers: this.headers,
    });

    switch (response.status) {
      case HttpStatusCode.serverError:
        throw new Error('Erro no servidor');
      default:
        return response.data;
    }
  }

}
