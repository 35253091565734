// import { useState } from "react";
// import { SearchInput } from "../input";
import { Order } from "./order";
import { AdvanceFilter } from "./AdvanceFilter";
import { useMedList } from "../../contexts/med-list";

export function Filters() {
  const { pagination } = useMedList();
  // const [search, setSearch] = useState("")

  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-4">
        {/* <SearchInput value={search} setValue={setSearch} /> */}
        <AdvanceFilter />
      </div>

      <div className="flex gap-4 items-center">
        {pagination && <span className="text-sm text-[#636363]">Resultados encontrados: {pagination.total} itens</span>}
        <Order />
      </div>
    </div>
  )
}
