import { format, parseISO } from "date-fns";

export function formatToReal(value: number | string) {
  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

export function formatInfractionType(type: string) {
  switch (type) {
    case 'refund_request':
      return 'Pedido de reembolso';
    default:
      return 'Não definido';
  }
}

export function formatStatus(status: string) {
  switch (status) {
    case 'approved automatic':
      return 'Aprov. Auto';
    case 'approved manual':
      return 'Aprovado manualmente';
    case 'rejected':
      return 'Rejeitado';
    case 'resolved':
    case 'closed':
      return 'Resolvido';
    case 'urgent':
      return 'Urgente';
    case 'acknowledged':
      return 'Em espera';
    default:
      return 'Não definido';
  }
}

export function formatDate(dateString?: string) {
  if (dateString) {
    const date = parseISO(dateString)

    const formattedDate = format(date, 'dd/MM/yyyy');
    const formattedTime = format(date, 'HH:mm');

    return [formattedDate, formattedTime];
  }

  return ['', '']
}

export function formatCpfCnpj(document: string | undefined) {
  if (document) {
    const numeros = document.replace(/\D/g, '');

    if (numeros.length === 11) {
      return numeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    if (numeros.length === 14) {
      return numeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }

    return 'Número inválido';
  }

  return 'Sem documento';
}
