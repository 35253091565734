import { useAuth } from '@/shared/context/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { Outlet } from "react-router-dom"

const PrivateRoute = () => {
  const { getUserId } = useAuth()
  const location = useLocation();

  if (!getUserId()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
