import MedViewDetail from "@/presentation/Med/pages/view/detail"
import { makeRemoteGetInfractionTransaction } from "../useCases/GetInfractionTransaction"
import { makeRemotePutInfractionReport } from "../useCases/PutInfractionReport";

export const MakeMedDetails = () => {
  const remoteGetInfractionTransaction = makeRemoteGetInfractionTransaction();
  const remotePutInfractionReport = makeRemotePutInfractionReport();

  return (
    <MedViewDetail remoteGetInfractionTransaction={remoteGetInfractionTransaction} remotePutInfractionReport={remotePutInfractionReport} />
  )
}
