import { PutInfractionReport } from "@/domain/useCase/putInfractionReport"
import { KEY_CACHE } from "@/infra/cache/constants/keys"
import { LocalStorageAdapter } from "@/infra/cache/localStorageAdapter"
import { API_ENDPOINTS, makeApiUrl } from "@/infra/http/constants/url"
import { FetchHttpClient } from "@/infra/http/fetchHttpClient"
import { RemotePutInfractionReport } from "@/shared/data/useCases/putInfractionReport"
import { generateHeaderPrivate } from "@/shared/libs/generate"

export const makeRemotePutInfractionReport = (): PutInfractionReport => {
  const cache = new LocalStorageAdapter();
  const user = cache.get(KEY_CACHE.USER) as string;
  const account = cache.get(KEY_CACHE.ACCOUNT) as string;

  return new RemotePutInfractionReport(
    makeApiUrl(API_ENDPOINTS.Private.INFRACTION_REPORT),
    new FetchHttpClient(),
    generateHeaderPrivate(user, account)
  )
}
