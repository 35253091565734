import { Main } from "../shared/layout/Main";
// import Dashboard from "../presentation/Dashboard";
import Login from "../presentation/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import MedViewHistoric from "../presentation/Med/pages/view/historic";
import { MakeMedList } from "@/factory/pages/MedList";
import { MakeMedDetails } from "@/factory/pages/MedDetails";
import PublicRoute from "./components/public-route";
import PrivateRoute from "./components/private-route";

export default function RoutesComponent() {
  return (
    <Routes>
      <Route element={<PublicRoute />} >
        <Route path="/login" element={
          <Login />
        } />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route element={<Main />}>
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="/med" element={<MakeMedList />} />
          <Route path="/med/detalhes/:id" element={<MakeMedDetails />} />
          <Route path="/med/historico/:id" element={<MedViewHistoric />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  )
};
