import { PostInfractionList } from "@/domain/useCase/postInfractionList"
import { KEY_CACHE } from "@/infra/cache/constants/keys"
import { LocalStorageAdapter } from "@/infra/cache/localStorageAdapter"
import { API_ENDPOINTS, makeApiUrl } from "@/infra/http/constants/url"
import { FetchHttpClient } from "@/infra/http/fetchHttpClient"
import { RemotePostInfractionList } from "@/shared/data/useCases/postInfractionList"
import { generateHeaderPrivate } from "@/shared/libs/generate"

export const makeRemotePostInfractionList = (): PostInfractionList => {
  const cache = new LocalStorageAdapter();
  const user = cache.get(KEY_CACHE.USER) as string;
  const account = cache.get(KEY_CACHE.ACCOUNT) as string;

  return new RemotePostInfractionList(
    makeApiUrl(API_ENDPOINTS.Private.INFRACTION_LIST),
    new FetchHttpClient(),
    generateHeaderPrivate(user, account)
  )
}
