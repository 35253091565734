import ReactPaginate from "react-paginate";
import { useMedList } from "../../contexts/med-list";
import "./styles.css";
import { ChevronLeft, ChevronRight } from "lucide-react";

export const Pagination = () => {
  const { pagination, updatePagination } = useMedList();

  const handlePageChange = (page: { selected: number }) => {
    updatePagination(page.selected);
  }

  return (
    <>
      {pagination &&
        <ReactPaginate
          breakLabel="..."
          nextLabel={<ChevronRight />}
          previousLabel={<ChevronLeft />}
          containerClassName={"pagination"}
          pageClassName={"pagination__link"}
          activeClassName={"pagination__link--active"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          breakLinkClassName={"pagination__break--link"}
          onPageChange={handlePageChange}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          pageCount={Math.ceil(pagination.total / pagination.pageSize)}
        />
      }
    </>
  )
}
